// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-js": () => import("./../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("./../src/pages/my_files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-post-or-page-js": () => import("./../src/pages/postOrPage.js" /* webpackChunkName: "component---src-pages-post-or-page-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-pages-js": () => import("./../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-posts-js": () => import("./../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

